import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import IntakeForm from "./IntakeForm";
import { Container, Grid } from "@mui/material";
import { Login } from "./auth";

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    console.log(user);
    console.log(isSignedIn);
  } , [user, isSignedIn]);

  return (
    <div className="App">
      <header></header>
      <body>
        <Login setUser={setUser} setIsSignedIn={setIsSignedIn} />
        <Container maxWidth="md">
          <Grid container spacing={2}>
            {isSignedIn ? (
              <IntakeForm name={user.name} email={user.email} />
            ) : ""}
          </Grid>
        </Container>
      </body>
    </div>
  );
}

export default App;