/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { Grid, Button, CircularProgress, Typography, Paper, AppBar, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";


export function generateState(length) {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
    var charLength = chars.length;
    var result = '';
    for ( var i = 0; i < length; i++ ) {
       result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
 }

 // Get atlassian token from auth code
export function getToken(code) {
    return new Promise((resolve, reject) => {
        const data = {
            grant_type: 'authorization_code',
            client_id: 'saIoDrm0Nhz6uxFDy6NI26QgEB8JYBEo',
            client_secret: 'ATOAZRqhJPiVJvfL2d80SYRBUdE1kjp1BM1aSOKM7mdQtICh_CcYPvXLrV3-nwU2QmL7B5BD8311',
            code: code,
            redirect_uri: 'https://product-intake-form.secondsightsolutions.com'
        };
        const headers = {
            'Content-Type': 'application/json'
        };
        axios.post('https://auth.atlassian.com/oauth/token', data, { headers: headers })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function refreshToken(token) {
    return new Promise((resolve, reject) => {
        const data = {
            grant_type: 'refresh_token',
            client_id: 'saIoDrm0Nhz6uxFDy6NI26QgEB8JYBEo',
            client_secret: 'ATOAZRqhJPiVJvfL2d80SYRBUdE1kjp1BM1aSOKM7mdQtICh_CcYPvXLrV3-nwU2QmL7B5BD8311',
            refresh_token: token,
            redirect_uri: 'https://product-intake-form.secondsightsolutions.com'
        };
        const headers = {
            'Content-Type': 'application/json'
        };
        axios.post('https://auth.atlassian.com/oauth/token', data, { headers: headers })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function Login(props) {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code");
    
        var t = JSON.parse(localStorage.getItem("token"));
    
        if (t && moment().isBefore(t.expires_at)) {
          setIsLoading(true);
          fetch("/api/currentuser", {
            headers: {
              Authorization: `Bearer ${t.access_token}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setUser(data);
              setIsSignedIn(true);
              setIsLoading(false);
              return;
            })
            .catch((error) => {
              localStorage.removeItem("token");
              isSignedIn(false);
              setIsLoading(false);
              console.log(error);
            });
          } else if (t) {
            setIsLoading(true);
            refreshToken(t.refreshToken).then((authResponse) => {
              localStorage.setItem("token", JSON.stringify({...authResponse, expires_at: moment().add(authResponse.expires_in, 'seconds')}));
              fetch("/api/currentuser", {
                headers: {
                  Authorization: `Bearer ${authResponse.access_token}`,
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  window.history.pushState('', 'Request Form', '/');
                  setUser(data);
                  setIsLoading(false);
                  setIsSignedIn(true);
                })
                .catch((error) => {
                  localStorage.removeItem("token");
                  setIsSignedIn(false);
                  setIsLoading(false);
                  console.log(error);
                });
            }).catch((error) => {
              localStorage.removeItem("token");
              setIsSignedIn(false);
              setIsLoading(false);
              console.log(error);
            });
          } else if (code) {
            setIsLoading(true);
            getToken(code).then((authResponse) => {
              localStorage.setItem("token", JSON.stringify({...authResponse, expires_at: moment().add(authResponse.expires_in, 'seconds')}));
              fetch("/api/currentuser", {
                headers: {
                  Authorization: `Bearer ${authResponse.access_token}`,
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  window.history.pushState('', 'Request Form', '/');
                  setUser(data);
                  setIsLoading(false);
                  setIsSignedIn(true);
                })
                .catch((error) => {
                  localStorage.removeItem("token");
                  isSignedIn(false);
                  setIsLoading(false);
                  console.log(error);
                });
            });
          }
      }, []);

      useEffect(() => {
        props.setUser(user);
        props.setIsSignedIn(isSignedIn);
      } , [user, isSignedIn]);

      const handleLogout = () => {
        localStorage.removeItem("token");
        setIsSignedIn(false);
        setUser(null);
        setIsLoading(false);
      }

    if (user) {
        return (
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" textAlign="left" component="div" sx={{ flexGrow: 1 }}>
                        Request Form
                    </Typography>
                    <div>
                        <Typography textAlign="right" variant="caption" component="div" sx={{ flexGrow: 1 }}>
                            Logged in as {user.name}
                        </Typography>
                        <Button color="inherit" onClick={() => handleLogout()}>Logout</Button>
                    </div>
                </Toolbar>
            </AppBar>
        )
    } else if (isLoading) {
        return (
            <CircularProgress />
        )
    } else {
        return (
            <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
                <Grid item>
                    <Paper elevation={4}>
                        <Grid
                            p={3}
                            container
                            spacing={2}
                            direction="column"
                            alignItems="center"
                        >
                            <Grid item xs={3}>
                                <Typography variant='h4'>Login</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='p'>Please login to view the form</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <a href="https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=saIoDrm0Nhz6uxFDy6NI26QgEB8JYBEo&scope=read%3Ame%20offline_access&redirect_uri=https%3A%2F%2Fproduct-intake-form.secondsightsolutions.com&state=none&response_type=code&prompt=consent">
                                    <Button fullWidth variant="contained">Login</Button>
                                </a>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}