import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography
} from "@mui/material";

export default function IntakeForm(props) {
  const [submitted, setSubmitted] = React.useState(false);
  const [kind, setKind] = React.useState();
  const [issueId, setIssueId] = React.useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/api/submit", {
        method: "POST",
        body: (new FormData(e.currentTarget)),
        }).then((response) => {
          console.log(response);
          return response.json();
        }).then((data) => {
          console.log(data);
          setIssueId(data.key);
        }).catch((error) => {
          console.log(error);
        });
    setSubmitted(true);

    };

  if (submitted) {
    return <React.Fragment>
        <Grid xs={12} pt={4}>
          <Typography variant="h5">Thank you for submitting your request!</Typography>
        </Grid>
        <Grid xs={12} pt={2}>
          <Typography variant="body1">Your request has been created with id {issueId}.</Typography>
        </Grid>
      </React.Fragment>;
  } else {
    return (
      <React.Fragment>
        <Grid xs={12} pt={4}>
          <Typography variant="h3">Development Request Form</Typography>
        </Grid>
        <form
          target="_blank"
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <input type="hidden" name="name" value={props.name} />
          <input type="hidden" name="email" value={props.email} />
          <Grid xs={12} pt={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="type">
                Feature request or a bug?
              </InputLabel>
              <Select
                required
                fullWidth
                id="type"
                label="Feature request or a bug?"
                name="type"
                onChange={(e) => {setKind(e.target.value)}}
              >
                <MenuItem value="story">Feature Request</MenuItem>
                <MenuItem value="bug">Bug</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} pt={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="related_product">
                What product does this relate to?
              </InputLabel>
              <Select
                required
                id="related_product"
                label="What product does this relate to?"
                name="relatedProduct"
              >
                <MenuItem value="binary">Binary</MenuItem>
                <MenuItem value="claim_export">Claim Export</MenuItem>
                <MenuItem value="manufacturer_policy">
                  ESP 2 - Manufacturer Policy
                </MenuItem>
                <MenuItem value="ovi">OVI</MenuItem>
                <MenuItem value="mbs">Medical Benefits Scrubbing</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} pt={2}>
            <FormControl fullWidth>
              <TextField
                required
                fullWidth
                id="request"
                label={kind === "story" ? "Clearly state what the development request is?" : "Clearly state what the issue is?"}
                name="request"
                multiline
                minRows={3}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} pt={2}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="client"
                label="Which client?"
                name="client"
              />
            </FormControl>
          </Grid>
          {kind === "story" && (
          <Grid xs={12} pt={2}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="timeline"
                label="Please provide timeline or target date for completion"
                name="timeline"
                multiline
              />
            </FormControl>
          </Grid>
          )}
          <Grid xs={12} pt={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="priority">
                Priority
              </InputLabel>
              <Select
                required
                fullWidth
                id="priority"
                label="Priority?"
                name="priority"
              >
                <MenuItem value="Critical">Critical</MenuItem>
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Lowest">Lowest</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} pt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </form>
      </React.Fragment>
    );
  }
}
